@import 'styles/_variables';
@import 'styles/_mixins';

.ImageUpload {
    width: 200px;
    height: 200px;
    border: 1px solid rgba(81, 85, 102, 0.2);
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    &--small {
        width: 120px;
        height: 120px;

        @include screen-sm {
            width: 100px;
            height: 100px;
        }
    }

    &--invalid {
        border-color: $color-red;
    }

    &__input {
        display: none;
    }

    &__uploader {
        display: flex;
        flex-direction: column;
        align-items: center;

        &-icon {
            margin-bottom: $spacing;
            width: 40px;
        }

        &-text {
            font-size: 14px;
            color: #515566;
            opacity: 0.8;
            text-align: center;
        }
    }

    &__preview {
        position: absolute;
        width: 100%;
        height: 100%;

        &-Avatar {
            width: 100%;
            height: 100%;
        }

        &-remove {
            position: absolute;
            right: 5px;
            top: 5px;
            font-size: 30px;
            color: $color-red;
        }
    }
}
