//-------------------------------------//
// MEDIA QUERIES
$media-lg: 1440px;
$media-md: 1280px;
$media-md-s: 1024px;
$media-sm: 768px;
$media-xs: 480px;

//-------------------------------------//
// COLORS
// Projects color palette

$color-brand-blue: #173496;

$color-green: #24A83D;
$color-red: #EB5757;
$color-lightgray: #E8EBF2;

$color-body: #535353;
$color-link: $color-body;

$color-button-blue: #142D84;
$color-button-green: $color-green;

$background-color-body: #F3F6FE;

$color-darkgray: #334150;
$color-white: #fff;

//-------------------------------------//
// FONTS
$font-family-montserrat: 'Montserrat', arial, sans-serif;
$font-family-roboto: 'Roboto', arial, sans-serif;

$font-family-body: $font-family-montserrat;
$font-color-body: $color-body;

$font-size-body: 14px;
$font-size-bg: 21px;
$font-size-lg: 38px;
$font-size-md: 16px;

$font-light: 300;
$font-normal: 400;
$font-medium: 500;
$font-bold: 700;
$font-bolder: 900;


//-------------------------------------//
// SPACING
$spacing: 20px;
$spacing-half: $spacing / 2;
$spacing-double: $spacing * 2;
$spacing-third: $spacing * 3;

//-------------------------------------//
// Z-INDEXING
// $zIndex-AppHeaderNav: 1000;
// $zIndex-AppSideNav: 1010;
// ...

//-------------------------------------//
// Global Variables

// Radius
$border-radius-button: 30px;
$border-radius-small: 5px;
$border-radius-medium: 20px;
$border-radius-large: 30px;

// Box Shadow
$box-shadow-light: 0 10px 60px rgba(35, 37, 46, 0.1);
$box-shadow-medium: 0 10px 60px rgba(35, 37, 46, 0.1);
$box-shadow-heavy: 0 10px 60px rgba(35, 37, 46, 0.1);

//-------------------------------------//
// Component Variables
// App Header Nav
$AppHeaderNav-height: 80px;
$AppHeaderNav-z-index: 9999;
$AppHeaderNavSideNav-width: 250px;
$AppHeaderNavSideNav-z-index: 9999;

// App Side Nav
// $AppSideNav-width: 240px;
// $AppSideNav-z-index: 160ms;

// Page
$PageBody-max-width: 992px;
$PageBody-margin-top: -125px;

// ActivityIndicator
$ActivityIndicator-stroke: $color-brand-blue;

// Input
$Input-height: 48px;
$Input-background-color: #fff;
$Input-font-family: $font-family-body;
$Input-font-size: 14px;
$Input-color: $color-body;

//ItemPickerView
$ItemPickerView-height: 48px;
$ItemPickerView-border: 1px solid rgba(81, 85, 102, 0.2);
$ItemPickerView-background-color: #fff;
$ItemPickerView-placeholder-font-size: 14px;
$ItemPickerView-placeholder-color: #515566;
$ItemPickerView-placeholder-opacity: 0.4;
$ItemPickerView-font-family: $font-family-body;
$ItemPickerView-color: $color-body;

// CheckBox
// $CheckBox-border: 2px solid #E4F0FC;
// $CheckBox-color: #AE77EA;
// $CheckBox-checked-background-color: #AE77EA;

// RadioBox
// $RadioBox-border: 2px solid #E4F0FC;
// $RadioBox-hover-border: 7px solid #e9dcf7;
// $RadioBox-hover-background-color: rgba(#AE77EA, 0.5);
// $RadioBox-checked-border: 7px solid #e9dcf7;
// $RadioBox-checked-background-color: #AE77EA;

// Input
$Input-background-color: #fff;
$Input-border: 1px solid rgba($color-darkgray, 0.25);
$Input-border-color-valid: $color-green;
$Input-border-color-invalid: $color-red;
$Input-border-radius: $border-radius-medium;
$Input-padding: 0 16px;
$Input-height: 56px;
$Input-height-screen-md: 45px;
$Input-font-size: 12px;
$Input-placeholder-color: $color-darkgray;
$Input-readonly-color: #8898AA;
$Input-readonly-background-color: #E9ECEF;
