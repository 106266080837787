@import 'styles/_variables';
@import 'styles/_mixins';

.ServiceFormScreen {
    height: 100%;
    overflow: auto;

    &__header {
        background-color: $color-brand-blue;
        height: 178px;
        border-radius: 0 0 $border-radius-large $border-radius-large;

        &-title {
            font-weight: 500;
            font-size: 18px;
            color: #fff;
            text-align: center;
            padding-top: 15px;
        }
    }

    &__body {
        background-color: #fff;
        border-radius: $border-radius-medium;
        padding: 35px 37px;
        max-width: $PageBody-max-width;
        margin: 0 auto;
        margin-top: $PageBody-margin-top;
        margin-bottom: $spacing-third;

        @include screen-sm {
            padding-left: $spacing;
            padding-right: $spacing;
        }

        &-title {
            font-size: 24px;
            padding: 15px 0;
        }

        &-desc {
            font-weight: normal;
            font-size: 14px;
        }

        &-row {
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            column-gap: 16px;
            margin-bottom: 30px;

            &--col6 {
                grid-template-columns: repeat(2, 1fr);
            }

            &--col4 {
                grid-template-columns: repeat(4, 1fr);
            }

            &-InputGroup {
                display: flex;
                flex-direction: column;

                @include screen-sm {
                    grid-column: span 3 !important;
                    margin-bottom: $spacing;
                }

                &--span2 {
                    grid-column: span 2;
                }

                &--span3 {
                    grid-column: span 3;
                }

                &-label {
                    font-family: $font-family-montserrat;
                    font-size: 14px;
                    line-height: 17px;
                    color: #515566;
                    opacity: 0.8;
                    margin-bottom: $spacing-half;
                }

                &-Input {
                    border-radius: 0;
                    height: 48px;
                    border: 1px solid rgba(81, 85, 102, 0.2);

                    &::placeholder {
                        color: #515566;
                        opacity: 0.4;
                    }
                }

                &-items {
                    display: grid;
                    grid-template-columns: repeat(3, 1fr);
                    column-gap: 16px;
                }
            }
        }

        &-submit {
            width: 160px;
            height: 44px;
        }
    }
}
