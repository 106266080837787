/*
 *
 * <ActivityIndicator />
 *
 */
.ActivityIndicator {
    stroke: $ActivityIndicator-stroke;
}

.Input {
    height: $Input-height;
    padding: $Input-padding;
    background-color: $Input-background-color;
    font-family: $Input-font-family;
    font-size: $Input-font-size;
    border: $Input-border;
    border-radius: $Input-border-radius;
    color: $Input-color;

    @include screen-md {
        height: $Input-height-screen-md;
    }

    &::placeholder {
        color: $Input-placeholder-color;
    }

    &:read-only {
        cursor: default;
        color: $Input-readonly-color;
        background-color: $Input-readonly-background-color;
    }

    &--isValid {
        border-color: $Input-border-color-valid;
    }

    &--isInvalid {
        border-color: $Input-border-color-invalid;
    }

    &--textarea {
        resize: none;
        padding: 16px;
    }
}

/*
 *
 * <Button />
 *
 */
.Button {
    transition-duration: 100ms;

    &--default {
        &:hover {
            box-shadow: $box-shadow-light;
        }

        &:active {
            color: $color-brand-blue;
            background-color: #fff;
            box-shadow: inset 0 0 3px rgba(#000, 1);
        }
    }

    &--default.Button--processing {
        color: $color-brand-blue;
        background-color: #fff;
        box-shadow: inset 0 0 3px rgba(#000, 1);
    }

    &--solid-blue {
        border-radius: $border-radius-button;
        color: #fff;
        background-color: $color-brand-blue;
    }

    &--solid-green {
        background: #24a83d;
        padding: 23px 40px;
        border-radius: $border-radius-button;
        font-family: $font-family-montserrat;
        font-weight: 600;
        font-size: 14px;
        text-transform: uppercase;
        text-align: center;
        letter-spacing: 1px;
        color: #fff;
    }

    &--solid-green-form {
        background: linear-gradient(0deg, #2baf00 0%, #9aed2f 100%), #24a83d;
        border-radius: 30px;
        font-family: $font-family-roboto;
        font-weight: 500;
        font-size: 14px;
        color: #fff;
    }
}

/*
 *
 * <ItemPickerView />
 *
 */
.ItemPickerView {
    height: $ItemPickerView-height;
    border: $ItemPickerView-border;
    background-color: $ItemPickerView-background-color;

    &__items {
        &-wrapper {
            &-placeholder {
                font-family: $ItemPickerView-font-family;
                font-size: $ItemPickerView-placeholder-font-size;
                color: $ItemPickerView-placeholder-color;
                opacity: $ItemPickerView-placeholder-opacity;
            }
        }
    }

    &__dropdown {
        background-color: $ItemPickerView-background-color;
        border-left: 0 none;
        min-width: 0;
        width: auto;
        margin-right: $spacing-half;
        margin-left: 0;
    }
}

/*
 *
 * <CheckBox />
 *
 */
// .CheckBox {
//     &__box {
//         border: $CheckBox-border;
//         color: $CheckBox-color;
//
//         &--checked {
//             background-color: $CheckBox-checked-background-color;
//         }
//     }
// }

/*
 *
 * <RadioBox />
 *
 */
// .RadioBox {
//     &__box {
//         border: $RadioBox-border;
//
//         &:hover {
//             border: $RadioBox-hover-border;
//             background-color: $RadioBox-hover-background-color;
//         }
//
//         &--checked {
//             border: $RadioBox-checked-border !important;
//             background-color: $RadioBox-checked-background-color !important;
//         }
//     }
// }
