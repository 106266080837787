@import 'styles/_variables';

.time_picker {
    padding: 1px;
    position: relative;

    &-invalid {
        border: 1px solid $color-red;
    }

    .react-time-picker {
        &__wrapper {
            display: flex;
            align-items: center;
        }

        &__clock-button {
            display: none;
        }

        &__inputGroup {
            display: flex;
            align-items: center;

            &__input {
                width: 54px !important;
                height: 38px;
                border: $Input-border;
                font-size: 20px;
                padding-left: 8px;
                font-family: $font-family-montserrat;
            }

            &__divider {
                font-weight: bold;
                font-size: 20px;
                margin: 0 1px;
            }

            &__leadingZero {
                display: none;
            }

            &__amPm {
                font-size: 16px;
                padding-left: 5px;
                margin-left: 3px;
            }
        }

        &__clear-button {
            margin-left: 5px;
            width: 24px;
            height: 24px;
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 5px;
        }
    }
}
