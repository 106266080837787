@import 'styles/_variables';
@import 'styles/_mixins';

// Only visible on mobile !
.AppHeaderNavSideNav {
    display: none;
    position: fixed;
    top: $AppHeaderNav-height;
    right: 0;
    height: 100%;
    transform: translateX($AppHeaderNavSideNav-width);
    transition: transform 150ms ease-in-out;
    width: $AppHeaderNavSideNav-width;
    z-index: $AppHeaderNavSideNav-z-index;
    background-color: #fff;

    &--isOpen {
        transform: translateX(0);
    }

    @include screen-md {
        display: flex;
        align-items: flex-end;
        flex-direction: column;
    }

    &__close {
        width: 60px;
        height: $AppHeaderNav-height;
        color: #000;
        font-size: 20px;
    }

    &__nav {
        display: flex;
        flex-direction: column;
        width: 100%;
        border-top: 1px solid $color-brand-blue;

        &-button {
            width: 100%;
            height: 50px;

            &--text {
                justify-content: flex-start !important;
                padding-left: $spacing;
                padding-right: $spacing;
                font-size: 18px;
                font-weight: bold;

                &:hover {
                    text-decoration: underline;
                }

                &--isActive {
                    background-color: $color-lightgray;
                }
            }

            &--login,
            &--profile {
                align-self: center;
                width: 122px;
                height: 36px;
                margin-top: $spacing;
                background-color: $color-button-blue;
                color: #fff;
                font-weight: $font-medium;
                border-radius: $border-radius-large;
            }
        }
    }
}

.AppHeaderNavSideNavBackdrop {
    display: none;
    position: fixed;
    top: $AppHeaderNav-height;
    left: 0;
    width: 100%;
    height: 100%;
    transform: translateX(-100%);
    transition: opacity 250ms ease-in-out;
    background-color: #000;
    opacity: 0;

    &--isOpen {
        transform: translateX(0);
        opacity: 0.2;
    }

    @include screen-md {
        display: block;
    }
}
