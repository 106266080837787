@import 'styles/_variables';
@import 'styles/_mixins';

.AppHeaderNav {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: $AppHeaderNav-height;
    padding: 0 $spacing-double;
    z-index: 0;
    background-color: #fff;


    @include screen-md {
        padding: 0 $spacing;
    }

    &__logo {
        width: 98px;
    }

    &__lang.ItemPickerView {
        margin-left: 10px;
        height: 38px;
        z-index: 99999;
    }

    &__nav {
        display: flex;
        margin-left: auto;

        &-button {
            display: flex;
            margin: 0 18px;
            min-width: 80px;

            @include screen-sm {
                display: none !important;
            }

            &:last-child {
                margin-right: 0;
            }

            &--text {
                font-size: 16px;

                &--isActive {
                    color: $color-brand-blue;
                }
            }

            &--login,
            &--profile {
                width: 122px;
                height: 36px;
                background-color: $color-button-blue;
                color: #fff;
                font-weight: $font-medium;
                border-radius: $border-radius-large;
            }

            &--menu {
                display: none !important;
                width: 60px;
                font-size: 20px;
                position: relative;
                left: $spacing;

                @include screen-sm {
                    display: flex !important;
                }
            }
        }
    }
}
